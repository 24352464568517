@font-face {
  font-family: 'GeneralSans';
  src: url('./fonts/general-sans/GeneralSans-Variable.woff2');
}

body {
  font-family: 'GeneralSans' !important;
  background: #f6f8fb;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@media screen and (min-width: 600px) {
  body {
    overflow-y: hidden;
  }
}

.scrollbar-cus {
  overflow: auto;
}

.scrollbar-cus::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-cus::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-cus::-webkit-scrollbar-thumb {
  background: #7495ff;
  border-radius: 5px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1) 100%);
  border-radius: 10px;
}

.paper-container {
  box-shadow: none !important;
  background: #fff;
  padding: 16px;
  height: 77vh;
  min-height: 77vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.paper-customization {
  box-shadow: none !important;
  border-radius: 16px !important;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.anchorLinks {
  color: #2c58e8;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

#toolbar {
  display: flex;
  justify-content: space-between;
  position: relative;
}

#toolbar::after {
  content: none;
}

.login-buttons {
  font-size: 14px !important;
  margin-top: 14px !important;
  padding: 17px !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 30px !important;
  text-align: center !important;
}

.about-company p {
  line-height: 22px;
  margin: 0px;
}

#app-textArea {
  height: 100%;
  resize: none;
  border: none;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif !important;
  color: #8c8e94;
}

textarea#app-textArea:focus,
textarea#app-textArea:active {
  border: none !important;
}

.upload-text {
  font-size: 14px !important;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif !important;
}

.filter-cells-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 14px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.inputFocusDisable:focus {
  outline: none !important;
  border: 1px solid #b2b3b7 !important;
}

@media only screen and (max-width: 400px) {
  body {
    overflow-y: auto;
  }
}

.button-medium {
  min-width: 120px !important;
}

.button-large {
  min-width: 196px !important;
}

.buttonText-large {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.buttonText-medium {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.flex-center {
  display: flex;
  align-items: center;
}
